<template>
  <div v-if="melvioLink" class="banner" :style="{ backgroundImage: `url(${backgroundImage})` }">
    <div class="text-box-1">
      {{ $t('melvio.bannerTitle') }}
    </div>
    <div class="text-box-2">
      {{ $t('melvio.bannerFirstLine') }}
    </div>
    <div class="ul">
      <li>
        {{ $t('melvio.bannerSecondLine') }}
      </li>
      <li>
        {{ $t('melvio.bannerThirdLine') }}
      </li>
    </div>
    <div class="text-box-3">
      {{ $t('melvio.bannerFourthLine') }}
    </div>
    <div class="text-box-4">
      {{ $t('melvio.bannerFifthLine') }}
    </div>
    <b-button class="button" tag="a" :href="melvioLink" :aria-label="$t('melvio.toSurvey')">{{
      $t('melvio.toSurvey')
    }}</b-button>
  </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-dom';
import hsmBanner from '../../../frontend-assets/img/banner-bg-wave.png';

export default defineComponent({
  components: {},
  props: {
    melvioLink: {
      type: String,
      required: false
    }
  },
  setup: (props, ctx) => {
    const backgroundImage = hsmBanner;
    const melviourl = props.melvioLink;

    return {
      backgroundImage,
      melviourl
    };
  }
});
</script>

<style scoped>
.banner {
  padding: 20px;
  text-align: left;
  color: #432a47;
  background: #b3ccb2;
  border-color: #432a47;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  border-radius: 15px;
  display: grid;
  box-sizing: border-box;
  height: 50%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto auto;
  justify-content: space-between;
}

.button {
  background-color: #efe0db;
  border-color: #dbdbdb;
  border-width: 1px;
  text-align: center;
  box-sizing: border-box;
  text-size-adjust: 50%;
  grid-column: 2;
  grid-row: 3;
  justify-self: end; /* Aligns the button to the end of its grid area horizontally */
  align-self: center; /* Aligns the button to the center of its grid area vertically */
}

.text-box-1 {
  text-align: left;
  align-items: flex-start;
  padding-bottom: 5px;
  float: left;
  box-sizing: border-box;
  font-weight: bold;
  grid-column: 1;
  grid-row: 1;
}
.text-box-2 {
  text-align: left;
  align-items: flex-start;
  float: left;
  box-sizing: border-box;
  grid-column: 1;
  grid-row: 2;
}
.text-box-3 {
  text-align: left;
  align-items: flex-start;
  float: left;
  box-sizing: border-box;
  grid-column: 1;
  grid-row: 4;
}
.text-box-4 {
  text-align: left;
  align-items: flex-start;
  float: left;
  box-sizing: border-box;
  grid-column: 1;
  grid-row: 5;
}

.ul {
  list-style-type: disc;
  padding-bottom: 5px;
  float: left;
  box-sizing: border-box;
  grid-column: 1;
  grid-row: 3;
}

@media only screen and (max-width: 613px) {
  .banner {
    text-align: left;
    background-position: 00% 100%;
    height: 50%;
    grid-template-columns: 1fr;
  }

  .button {
    grid-column: 1;
    grid-row: 6;
    width: 90%;
    justify-self: center;
    margin-top: 5%;
  }
}

@media only screen and (max-width: 1407px) and (min-width: 614px) {
  .banner {
    text-align: left;
    height: 50%;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto;
    background-position-x: right;
  }

  .button {
    grid-column: 2;
    grid-row: 3;
    width: 100%;
    justify-self: center;
  }
}
</style>
