<style lang="scss" scoped>
h2 {
  font-weight: $weight-semibold;
  font-size: 1.2rem;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-weight: $weight-normal;
  .price {
    margin-left: 0.5rem;
  }
  .bold-price {
    @extend .price;
    font-weight: $weight-semibold;
  }
  .price-big {
    @extend .price;
    font-weight: $weight-semibold;
    font-size: 1.6rem;
    margin-top: 0.5rem;
  }
}

.vat {
  font-weight: $weight-normal;
  font-size: 0.8rem;
  text-align: right;
  margin-bottom: 0.5rem;
}

.total-container {
  grid-column-start: 2;
}

.spare-explanation {
  font-size: 0.8rem;
  font-weight: $weight-normal;
  font-style: italic;
}

.product-price {
  font-weight: $weight-semibold;
  font-size: 1.2rem;
}

.product-price-spare {
  font-weight: $weight-normal;
}

.groupedvatamounts .table tbody tr:last-child td {
  border-width: 1px 0 0 0;
}

a {
  color: inherit;
}

a.is-danger {
  color: #f14668;
  font-weight: bold;
}
.queue {
  margin-top: 1em;
}
.showInvoicesButton {
  height: 3rem;
  margin-bottom: 1rem;
  @include tablet {
    float: right;
  }
}
.title {
  @include tablet {
    display: inline-block;
  }
}
.description {
  margin-bottom: 1rem;
}
</style>
<style>
#registration-content .accessibility > p {
  /*
  A quickfix for preventing long accessibility links breaking down the layout.
  Effective only in the current view.
  Aligns the accessibility text to the left/centre when
  it should be aligned to the right in mobile width.
  */
  min-width: 0;
  max-width: 0;
}
</style>

<template>
  <div class="card">
    <div class="card-content" id="registration-content">
      <h1 class="title">
        {{ $t(titleTranslationKey) }}
      </h1>
      <a href="#invoices" v-if="hasInvoices && showInvoices"
        ><b-button
          type="is-primary"
          class="showInvoicesButton"
          :aria-label="$t('registration.showInvoices')"
        >
          {{ $t('registration.showInvoices') }}
        </b-button></a
      >
      <div
        class="description"
        v-if="registration.confirmationtext"
        v-dompurify-html="registration.confirmationtext"
      />
      <div
        class="description"
        v-if="registration.cancellationtext"
        v-dompurify-html="registration.cancellationtext"
      />
      <p class="queue" v-if="registration.queuetext" v-html="registration.queuetext"></p>

      <MelvioBanner :melvioLink="melvioLink" />
      <div
        :class="{
          twoColumn: registration.productstotal && registration.productstotal.amount === 0
        }"
      >
        <div class="block">
          <b-table :data="registration.products" class="products">
            <b-table-column
              field="registration"
              :label="$t('registration.product')"
              v-slot="props"
              :cell-class="'product-cell'"
            >
              <div>
                <h2>
                  <router-link
                    v-if="props.row.course"
                    :to="{ name: 'course', params: { id: props.row.course.id } }"
                  >
                    {{ props.row.course.name }}
                  </router-link>
                  <span v-else>{{ props.row.name }}</span>
                </h2>
                <div class="course-code" v-if="props.row.course">{{ props.row.course.code }}</div>
                <RegistrationStatus
                  v-if="props.row.type !== 'discount'"
                  :registration="props.row"
                  :fromCart="false"
                />
                <a
                  class="is-danger"
                  v-if="props.row.cancellationurl"
                  @click="cancelRegistration(props.row.cancellationurl)"
                  >{{ $t('registration.cancel') }} »
                </a>
                <p v-if="props.row.cancellationurl">
                  {{ $t('registration.lastcancellationdate') }}
                  {{ props.row.lastcancellationdate | date }}
                </p>
              </div>
            </b-table-column>

            <b-table-column
              field="client"
              :label="$t('registration.client')"
              v-slot="props"
              :cell-class="'product-cell'"
            >
              <div v-if="props.row.client">
                <h4>{{ props.row.client.firstname }} {{ props.row.client.lastname }}</h4>
                <p>{{ props.row.client.email }}</p>
                <p>{{ props.row.client.phone }}</p>
              </div>
            </b-table-column>

            <b-table-column
              field="priceclass"
              :visible="showPrices"
              :label="$t('registration.priceclass')"
              v-slot="props"
              :cell-class="'product-cell'"
            >
              <div v-if="props.row.items">
                <p v-for="item in props.row.items" :key="item.id">
                  {{ item.name }} {{ $n(item.unitprice / 100, 'currency') }}
                </p>

                <p v-if="props.row.total.amount">
                  {{ $t('registration.incvat') }}
                  {{ $n(props.row.total.vatpercentage / 100, 'percent') }}
                  {{ $n(props.row.total.vat / 100, 'currency') }}
                </p>
              </div>
            </b-table-column>

            <b-table-column
              field="price"
              :visible="showPrices"
              :label="$t('registration.price')"
              v-slot="props"
              :cell-class="'product-cell price-cell'"
              numeric
            >
              <div v-if="props.row.total">
                <span v-if="props.row.total.amount" class="product-price">
                  {{ $n(props.row.total.amount / 100, 'currency') }}
                </span>
                <span v-if="props.row.total.spareamount" class="product-price-spare">
                  {{ $n(props.row.total.spareamount / 100, 'currency') }}&nbsp;*
                </span>
              </div>
            </b-table-column>

            <b-table-column
              field="course"
              :visible="!showPrices"
              :label="$t('registration.details')"
              v-slot="props"
              :cell-class="'product-cell'"
            >
              <CourseInfoDl
                v-if="props.row.course && isEmpty(props.row.lessons)"
                :course="props.row.course"
                :fields="courseInfoDlFields"
                :spacing="false"
                :locationSingleLine="false"
                :locationMapLink="false"
              />
              <div v-else-if="props.row.course && !isEmpty(props.row.lessons)">
                <CourseInfoDl
                  class="block"
                  v-for="lesson in props.row.lessons"
                  :key="lesson.id"
                  :course="props.row.course"
                  :lesson="lesson"
                  :fields="courseInfoDlFields"
                  :spacing="false"
                  :locationSingleLine="false"
                  :locationMapLink="false"
                />
              </div>
            </b-table-column>
          </b-table>
        </div>

        <!-- summary: grouped VAT amounts and total amounts with explanations -->
        <div class="columns block" v-if="showPrices">
          <div class="column is-half-desktop">
            <b-table
              :data="groupedvatamounts"
              :narrowed="true"
              :mobile-cards="false"
              class="groupedvatamounts"
            >
              <b-table-column
                :label="$t('registration.vatpercentage')"
                v-slot="props"
                :header-class="'has-text-weight-normal'"
                width="25%"
              >
                {{ props.row.label }}
              </b-table-column>
              <b-table-column
                :label="`${$t('registration.net')} (€)`"
                numeric
                v-slot="props"
                :header-class="'has-text-weight-normal'"
                width="25%"
              >
                {{ $n(props.row.net / 100, 'twodecimal') }}
              </b-table-column>
              <b-table-column
                :label="`${$t('registration.vat')} (€)`"
                numeric
                v-slot="props"
                :header-class="'has-text-weight-normal'"
                width="25%"
              >
                {{ $n(props.row.vat / 100, 'twodecimal') }}
              </b-table-column>
              <b-table-column
                :label="`${$t('registration.gross')} (€)`"
                numeric
                v-slot="props"
                :header-class="'has-text-weight-normal'"
                width="25%"
              >
                {{ $n(props.row.amount / 100, 'twodecimal') }}
              </b-table-column>
            </b-table>
          </div>
          <div class="column is-half-desktop">
            <div class="block">
              <div class="row" v-if="registration.productstotal.amount">
                <div>{{ $t('cart.paymenttotal') }}</div>
                <div class="bold-price">
                  {{ $n(registration.productstotal.amount / 100, 'currency') }}
                </div>
              </div>

              <div class="row" v-if="registration.productstotal.culturevoucher">
                <div>{{ $t('cart.summary.culturevouchertotal') }}</div>
                <div class="price">
                  {{ $n(registration.productstotal.culturevoucher / 100, 'currency') }}
                </div>
              </div>

              <div class="row" v-if="registration.productstotal.sportsvoucher">
                <div>{{ $t('cart.summary.sportsvouchertotal') }}</div>
                <div class="price">
                  {{ $n(registration.productstotal.sportsvoucher / 100, 'currency') }}
                </div>
              </div>
            </div>

            <div class="block row" v-if="registration.productstotal.canpaynow">
              <div>{{ $t('cart.canpaynow') }}</div>
              <div class="price-big">
                {{ $n(registration.productstotal.canpaynow / 100, 'currency') }}
              </div>
            </div>
            <div class="block row" v-if="registration.productstotal.mustpaynow">
              <div>{{ $t('cart.mustpaynow') }}</div>
              <div class="price-big">
                {{ $n(registration.productstotal.mustpaynow / 100, 'currency') }}
              </div>
            </div>

            <div class="block">
              <div class="row" v-if="someRegistrationsAreSpare">
                <div>{{ $t('cart.sparetotal') }}&nbsp;*</div>
                <div class="price">
                  {{ $n(registration.productstotal.spareamount / 100, 'currency') }}
                </div>
              </div>
              <div class="row spare-explanation" v-if="someRegistrationsAreSpare">
                {{ $t('cart.spareExplanation') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import { DialogProgrammatic as Dialog } from 'buefy';
import { defineComponent, onBeforeMount, PropType, ref } from '@vue/composition-api';
import { isEmpty, propOr, some } from 'lodash/fp';

import {
  HellewiMyRegistrationsResponse,
  HellewiGetRegistrationResponse,
  HellewiPostRegistrationResponse
} from '@/frontend-src/api';

import CourseInfoDl, { CourseInfoDlField } from '../CourseInfoDl.vue';
import MelvioBanner from './MelvioBanner.vue';
import RegistrationStatus from './RegistrationStatus.vue';
import { useToast } from '../../utils/api-utils';
import { translate } from '../../utils/misc-utils';

import { useGetMelvioSurveyStatus } from '../../hooks/useExternalApi';
import Registration from '../Registration.vue';

interface GroupedVatAmounts {
  label: string;
  net: number;
  vat: number;
  amount: number;
}

export default defineComponent({
  components: { CourseInfoDl, RegistrationStatus, MelvioBanner },
  props: {
    registration: {
      type: Object as PropType<
        | HellewiMyRegistrationsResponse
        | HellewiGetRegistrationResponse
        | HellewiPostRegistrationResponse
      >,
      required: true
    },
    showRegistrationPrices: {
      type: Boolean,
      required: true
    },
    showInvoices: {
      type: Boolean,
      required: true
    },
    titleTranslationKey: {
      type: String,
      required: true
    }
  },
  setup: (props, ctx) => {
    const { invoices, products, productstotal, productsgroupedvatamounts } = props.registration;
    const { amount, mustpaynow, canpaynow, net, vat } = productstotal;

    const { response: MelvioSSResponse, execute: MelvioSSExecute } = useGetMelvioSurveyStatus();

    const { warnToast } = useToast(ctx);

    const router = ctx.root.$router;

    const allRegistrationsAreFree = amount === 0;
    const someRegistrationsAreSpare = some((p) => Boolean(p.spare), products);
    const showPrices = props.showRegistrationPrices && !allRegistrationsAreFree;

    const paymentlater = amount - (mustpaynow || 0) - (canpaynow || 0);

    const cancelRegistration = async (url: string) => {
      Dialog.confirm({
        message: translate(ctx, 'registration.cancelconfirm'),
        cancelText: translate(ctx, 'registration.cancelcancel'),
        confirmText: translate(ctx, 'registration.cancelok'),
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await axios.get(url);
            router.go(0);
          } catch (error) {
            warnToast('registration.cancelerror');
          }
        }
      });
    };

    const groupedvatamounts: GroupedVatAmounts[] = [
      ...productsgroupedvatamounts
        .sort((gva1, gva2) => (gva1.vatpercentage || 0) - (gva2.vatpercentage || 0))
        .map((gva) => ({
          label: ctx.root.$n((gva.vatpercentage || 0) / 100, 'percent'),
          net: gva.net,
          vat: gva.vat,
          amount: gva.amount
        })),
      // add total row only if there are more than one vat group
      ...(productsgroupedvatamounts.length > 1
        ? [
            {
              label: ctx.root.$t('registration.total') as string,
              net,
              vat,
              amount
            }
          ]
        : [])
    ];

    const melvioLink = ref<string>();

    onBeforeMount(async () => {
      if (props.registration.externalId) {
        await MelvioSSExecute({ surveyId: props.registration.externalId });

        melvioLink.value = MelvioSSResponse.value.surveyLink;
      }
    });

    const courseInfoDlFields: CourseInfoDlField[] = [
      CourseInfoDlField.Period,
      CourseInfoDlField.Weekdays,
      CourseInfoDlField.Location
    ];

    return {
      allRegistrationsAreFree,
      cancelRegistration,
      courseInfoDlFields,
      groupedvatamounts,
      isEmpty,
      paymentlater,
      showPrices,
      someRegistrationsAreSpare,
      hasInvoices: !isEmpty(invoices),
      melvioLink
    };
  }
});
</script>
